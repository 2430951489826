import { thunk, action } from 'easy-peasy'
import { API_URL } from '@/utils/constants'
import axios from 'axios'

export default {
  // Trip
  trip: JSON.parse(sessionStorage.getItem('booking')),
  setTrip: action((state, payload = null) => {
    state.trip = payload
    sessionStorage.setItem('booking', JSON.stringify(payload))
  }),
  // Trips
  trips: { status: 0, payload: null },
  setTrips: action((state, payload = null) => {
    state.trips = payload
  }),
  getTrips: thunk(async (actions, params = {}) => {
    actions.setTrips({ status: 1, payload: null })
    params.orderBy = 'departureTime'
    params.direction = 'asc'

    axios.get(`${API_URL}/trip`, { params })
      .then(response => actions.setTrips({ status: 2, payload: response.data }))
      .catch(e => actions.setTrips({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Retrieve Trip
  tripDetails: { status: 0, payload: null },
  setTripDetails: action((state, payload = null) => {
    state.tripDetails = payload
  }),
  getTripDetails: thunk(async (actions, params = {}) => {
    const { id = 0 } = params
    actions.setTripDetails({ status: 1, payload: null })
    axios.get(`${API_URL}/trip/${id}/?seatingArrangement=true`)
      .then(response => actions.setTripDetails({ status: 2, payload: response.data }))
      .catch(e => actions.setTripDetails({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Selected View Trip
  selectedViewTrip: null,
  setSelectedViewTrip: action((state, payload = null) => {
    state.selectedViewTrip = payload
  }),
  // Trip Employees
  tripEmployee: { status: 0, payload: null },
  setTripEmployee: action((state, payload = null) => {
    state.tripEmployee = payload
  }),
  updateTripEmployee: thunk(async (actions, data = {}) => {
    const { id = 0 } = data
    delete data.id
    actions.setTripEmployee({ status: 1, payload: null })
    axios.patch(`${API_URL}/trip/${id}/employee`, data)
      .then(response => actions.setTripEmployee({ status: 2, payload: response.data }))
      .catch(e => actions.setTripEmployee({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  })
}
