import React from 'react'
import ViewContainer from './container'
import { Spin, Row, Col, Input, Button } from 'antd'
import { FormItem } from '@/components/FormItem'

export default () => (
  <ViewContainer>
    {({ formik, state }) => (
      <Spin spinning={state.auth.status === 1}>
        <form method='post' onSubmit={formik.handleSubmit} noValidate>
          <FormItem
            formik={formik}
            label='Username'
            name='username'
          >
            {props => <Input {...props} placeholder='Username' size='large' />}
          </FormItem>
          <FormItem
            formik={formik}
            label='Password'
            name='password'
          >
            {props => <Input.Password {...props} placeholder='Password' size='large' />}
          </FormItem>
          <Row gutter={24} style={{ marginBottom: 48 }}>
            <Col span={24}>
              <Button type='primary' htmlType='submit' disabled={state.auth.status === 1} block>
                Submit
              </Button>
            </Col>
          </Row>
        </form>
      </Spin>
    )}
  </ViewContainer>
)
